var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: `fill-current  text-${_vm.color}`,
    attrs: {
      "height": `${_vm.height}px`,
      "width": `${_vm.width}px`,
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19 0.625H5C3.67441 0.626588 2.40356 1.15388 1.46622 2.09122C0.528882 3.02856 0.00158786 4.29941 0 5.625L0 19.625C0.00158786 20.9506 0.528882 22.2214 1.46622 23.1588C2.40356 24.0961 3.67441 24.6234 5 24.625V21.625C5 19.7685 5.7375 17.988 7.05025 16.6753C8.36301 15.3625 10.1435 14.625 12 14.625C13.8565 14.625 15.637 15.3625 16.9497 16.6753C18.2625 17.988 19 19.7685 19 21.625V24.625C20.3256 24.6234 21.5964 24.0961 22.5338 23.1588C23.4711 22.2214 23.9984 20.9506 24 19.625V5.625C23.9984 4.29941 23.4711 3.02856 22.5338 2.09122C21.5964 1.15388 20.3256 0.626588 19 0.625V0.625ZM12 12.625C11.2089 12.625 10.4355 12.3904 9.77772 11.9509C9.11992 11.5114 8.60723 10.8866 8.30448 10.1557C8.00173 9.42483 7.92252 8.62056 8.07686 7.84464C8.2312 7.06871 8.61216 6.35598 9.17157 5.79657C9.73098 5.23716 10.4437 4.8562 11.2196 4.70186C11.9956 4.54752 12.7998 4.62673 13.5307 4.92948C14.2616 5.23223 14.8864 5.74492 15.3259 6.40272C15.7654 7.06052 16 7.83387 16 8.625C16 9.68587 15.5786 10.7033 14.8284 11.4534C14.0783 12.2036 13.0609 12.625 12 12.625Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }