var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: `fill-current  text-${_vm.color}`,
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "height": `${_vm.height}px`,
      "width": `${_vm.width}px`
    }
  }, [_c('path', {
    attrs: {
      "d": "M20.4853 15.5147C19.1783 14.2077 17.6226 13.2402 15.9253 12.6545C17.7432 11.4024 18.9375 9.30694 18.9375 6.9375C18.9375 3.11217 15.8253 0 12 0C8.17467 0 5.0625 3.11217 5.0625 6.9375C5.0625 9.30694 6.25683 11.4024 8.07478 12.6545C6.37744 13.2402 4.82175 14.2077 3.51473 15.5147C1.24823 17.7812 0 20.7947 0 24H1.875C1.875 18.417 6.41705 13.875 12 13.875C17.583 13.875 22.125 18.417 22.125 24H24C24 20.7947 22.7518 17.7812 20.4853 15.5147ZM12 12C9.20855 12 6.9375 9.729 6.9375 6.9375C6.9375 4.146 9.20855 1.875 12 1.875C14.7915 1.875 17.0625 4.146 17.0625 6.9375C17.0625 9.729 14.7915 12 12 12Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }