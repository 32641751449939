<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_992_11055)">
      <path
        d="M8 21.625H3.409C3.30061 21.6247 3.19673 21.5816 3.12009 21.5049C3.04344 21.4283 3.00026 21.3244 3 21.216V4.034C3.00026 3.92561 3.04344 3.82173 3.12009 3.74509C3.19673 3.66844 3.30061 3.62526 3.409 3.625H8C8.39782 3.625 8.77936 3.46696 9.06066 3.18566C9.34196 2.90436 9.5 2.52282 9.5 2.125C9.5 1.72718 9.34196 1.34564 9.06066 1.06434C8.77936 0.783035 8.39782 0.625 8 0.625L3.409 0.625C2.5052 0.626059 1.63872 0.98556 0.999642 1.62464C0.36056 2.26372 0.00105862 3.1302 0 4.034L0 21.216C0.00105862 22.1198 0.36056 22.9863 0.999642 23.6254C1.63872 24.2644 2.5052 24.6239 3.409 24.625H8C8.39782 24.625 8.77936 24.467 9.06066 24.1857C9.34196 23.9044 9.5 23.5228 9.5 23.125C9.5 22.7272 9.34196 22.3456 9.06066 22.0643C8.77936 21.783 8.39782 21.625 8 21.625Z"
      />
      <path
        d="M22.6821 9.44239L18.8001 5.56339C18.6608 5.42413 18.4954 5.31367 18.3134 5.23832C18.1313 5.16298 17.9363 5.12422 17.7393 5.12427C17.5423 5.12431 17.3472 5.16316 17.1652 5.23859C16.9832 5.31402 16.8179 5.42456 16.6786 5.56389C16.5394 5.70323 16.4289 5.86863 16.3535 6.05065C16.2782 6.23267 16.2394 6.42775 16.2395 6.62475C16.2396 7.02261 16.3977 7.40413 16.6791 7.68539L20.0911 11.0964L6.50012 11.1244C6.1023 11.1244 5.72077 11.2824 5.43946 11.5637C5.15816 11.845 5.00012 12.2266 5.00012 12.6244C5.00012 13.0222 5.15816 13.4038 5.43946 13.6851C5.72077 13.9664 6.1023 14.1244 6.50012 14.1244L20.1461 14.0994L16.6791 17.5664C16.4143 17.8509 16.2702 18.227 16.2773 18.6156C16.2843 19.0042 16.4419 19.3749 16.7169 19.6496C16.9918 19.9243 17.3627 20.0816 17.7513 20.0882C18.1399 20.0949 18.5159 19.9505 18.8001 19.6854L22.6791 15.8064C23.097 15.3885 23.4285 14.8925 23.6546 14.3465C23.8808 13.8005 23.9972 13.2153 23.9972 12.6244C23.9972 12.0334 23.8808 11.4483 23.6546 10.9023C23.4285 10.3563 23.097 9.86026 22.6791 9.44239H22.6821Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_992_11055">
        <rect width="24" height="24" transform="translate(0 0.625)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '25'
    }
  }
}
</script>
