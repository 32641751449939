var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `arrow ${_vm.direction}`
  }, [_c('svg', {
    attrs: {
      "height": `${_vm.height}px` || '20px',
      "width": `${_vm.width}px` || '20px',
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.5625 1.25L16.3125 8L9.5625 14.75M15.375 8H1.6875",
      "stroke": "#58595B",
      "stroke-width": "2.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }