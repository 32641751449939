var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: `fill-current  text-${_vm.color}`,
    attrs: {
      "height": `${_vm.height}px` || '18px',
      "width": `${_vm.width}px` || '16px',
      "viewBox": "0 0 18 16",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0 0.5V3.5H18V0.5H0ZM0 9.5V6.5H18V9.5H0ZM0 15.5V12.5H18V15.5H0Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }