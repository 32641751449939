<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.54585 6.2655C9.44137 6.16096 9.31732 6.07803 9.18078 6.02145C9.04424 5.96486 8.89789 5.93574 8.7501 5.93574C8.6023 5.93574 8.45595 5.96486 8.31941 6.02145C8.18287 6.07803 8.05882 6.16096 7.95435 6.2655L6.1251 8.09475V1.5C6.1251 1.20163 6.00657 0.915483 5.79559 0.704505C5.58461 0.493526 5.29846 0.375 5.0001 0.375C4.70173 0.375 4.41558 0.493526 4.2046 0.704505C3.99362 0.915483 3.8751 1.20163 3.8751 1.5V8.09475L2.04585 6.2655C1.8349 6.05445 1.54875 5.93585 1.25036 5.93578C0.951966 5.93571 0.665766 6.05418 0.45472 6.26513C0.243674 6.47607 0.12507 6.76222 0.125 7.06061C0.12493 7.359 0.243399 7.64521 0.454345 7.85625L3.14385 10.5458C3.3876 10.7895 3.67699 10.9829 3.99549 11.1149C4.31399 11.2468 4.65535 11.3147 5.0001 11.3147C5.34484 11.3147 5.6862 11.2468 6.0047 11.1149C6.3232 10.9829 6.61259 10.7895 6.85635 10.5458L9.54585 7.85625C9.75675 7.64528 9.87523 7.35919 9.87523 7.06088C9.87523 6.76257 9.75675 6.47647 9.54585 6.2655Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '10'
    },
    height: {
      type: String,
      default: '12'
    }
  }
}
</script>
