var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: `fill-current  text-${_vm.color}`,
    attrs: {
      "height": `${_vm.height}px`,
      "width": `${_vm.width}px`,
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_992_11050)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19 3.625H5C3.67441 3.62659 2.40356 4.15388 1.46622 5.09122C0.528882 6.02856 0.00158786 7.29941 0 8.625H24C23.9984 7.29941 23.4711 6.02856 22.5338 5.09122C21.5964 4.15388 20.3256 3.62659 19 3.625Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M0 16.6247C0.00158786 17.9503 0.528882 19.2212 1.46622 20.1585C2.40356 21.0959 3.67441 21.6232 5 21.6247H19C20.3256 21.6232 21.5964 21.0959 22.5338 20.1585C23.4711 19.2212 23.9984 17.9503 24 16.6247V10.6248H0V16.6247ZM7 16.1247C7 16.4214 6.91203 16.7114 6.7472 16.9581C6.58238 17.2048 6.34811 17.397 6.07403 17.5106C5.79994 17.6241 5.49834 17.6538 5.20736 17.5959C4.91639 17.538 4.64912 17.3952 4.43934 17.1854C4.22956 16.9756 4.0867 16.7084 4.02882 16.4174C3.97094 16.1264 4.00065 15.8248 4.11418 15.5507C4.22771 15.2766 4.41997 15.0424 4.66664 14.8775C4.91332 14.7127 5.20333 14.6248 5.5 14.6248C5.89782 14.6248 6.27936 14.7828 6.56066 15.0641C6.84196 15.3454 7 15.7269 7 16.1247Z"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_992_11050"
    }
  }, [_c('rect', {
    attrs: {
      "width": "24",
      "height": "24",
      "transform": "translate(0 0.625)"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }